import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import Layout from '../containers/layout'
import Work from '../components/work'
import SEO from '../components/seo'

export const query = graphql`
  query WorkTemplateQuery($id: String!) {
    work: sanityWork(id: { eq: $id }) {
      id
      publishedAt
      _rawExcerpt
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      title
      slug {
        current
      }
      _rawBody2
      _rawBody
    }
  }
`

const WorkTemplate = props => {
  const { data, errors } = props
  const work = data && data.work
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {work && <SEO title={work.title || 'Untitled'} />}

      {errors && <GraphQLErrorList errors={errors} />}
      {work && <Work {...work} />}
    </Layout>
  )
}

export default WorkTemplate
